const useQuestTask = () => {
	const { open } = useTaoModals();
	const { handleOpenGame } = useOpenGame(open);
	const { data: questData } = useQuestData();
	const router = useRouter();
	const { playMagicAudio } = useMagicBoxAudios();
	const { handleSwitchGameMode, isSweepStakes } = useSwitchMode();

	const { execute: activeTaskDeposit } = useAsyncFetch({
		path: "/rest/player/balance/",
		method: "get",
		fetchOptions: {
			onResponse: ({ response }) => {
				if (!response._data.success) {
					return;
				}
				if (response._data.data.winnings < 1) {
					window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
					return;
				}
				window?.$cash?.$router?.push?.("/cash/deposit/");
			}
		},
		options: {
			immediate: false
		}
	});

	const openQuestTask = () => {
		const { isCompleted, isEnded, isLocked, tasks } = questData.value?.dayInfo || {};
		const activeTask = tasks?.find((task) => !task.isCompleted);

		const switchGameMode = () => {
			const { isSweepStakesModeAvailable, isTournamentPointsModeAvailable } = activeTask || {};

			if (isSweepStakesModeAvailable && isTournamentPointsModeAvailable) {
				return;
			}

			if (
				(isSweepStakesModeAvailable && !isSweepStakes.value) ||
				(isTournamentPointsModeAvailable && isSweepStakes.value)
			) {
				handleSwitchGameMode();
			}
		};

		if ((isCompleted && isEnded && isLocked) || !activeTask) {
			router.push("/quest/");
			return;
		}

		if (
			!activeTask ||
			!activeTask!.action!.type ||
			(activeTask!.action!.type === "gamesList" && activeTask!.action!.details?.games?.length)
		) {
			open("LazyOModalRacesGames");
			switchGameMode();
			return;
		}
		if (activeTask!.action!.type === "magicBox") {
			open("LazyOModalMagicBoxes");
			playMagicAudio();
			return;
		}
		if (activeTask!.action!.type === "game" && activeTask!.action!.details?.game) {
			handleOpenGame(activeTask!.action!.details.game.slug);
			switchGameMode();
			return;
		}
		if (activeTask!.action!.type === "deposit") {
			activeTaskDeposit();
			return;
		}
		router.push("/quest/");
	};

	return { openQuestTask };
};

export default useQuestTask;
